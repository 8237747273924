<template>
  <!-- 新增提现页面 -->
  <div class="addTopUpPageBox page-info-content">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      :inline="true"
      label-width="130px"
      class="demo-ruleForm page-form form-item-row"
    >
      <el-form-item label="会员名称" prop="cmpId">
        <el-input v-model="ruleForm.cmpName" disabled placeholder="会员名称" />
      </el-form-item>
      <el-form-item label="结算银行" prop="settleBankId">
        <el-select v-model="ruleForm.settleBankId" placeholder="请选择搜索" @change="getOpenAccNo">
          <!-- 取的是字典项 -->
          <el-option
            v-for="item in $store.getters.getDictionaryItem('settleBankName')"
            :key="item.dictId"
            :label="item.dictName"
            :value="item.dictId"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="渠道" prop="operPlat">
        <el-select v-model="ruleForm.operPlat" placeholder="请选择">
          <el-option label="平台" value="01" />
          <el-option label="银行" value="02" />
        </el-select>
      </el-form-item>
      <el-form-item label="手续费支付方" prop="feePayWay">
        <el-select v-model="ruleForm.feePayWay" placeholder="请选择" @change="getFeeAmt">
          <el-option label="平台" :value="1" />
          <el-option label="会员" :value="2" />
        </el-select>
      </el-form-item>
      <el-form-item class="withdrawalAmount" label="可提现金额金额(元)" label-width="150px">
        <span>{{ ruleForm.avlbCashFund }}</span>
      </el-form-item>
      <el-form-item label="提现金额(元)" prop="amount">
        <el-input v-model="ruleForm.amount" type="number" placeholder="请输入提现金额" @input="getFeeAmt" />
      </el-form-item>
      <el-form-item label="收款账号" prop="openAccNoId">
        <el-select v-model="ruleForm.openAccNoId" placeholder="请选择" @change="getOtherDetails">
          <el-option
            v-for="item in openAccNoArr"
            :key="item.id"
            :label="item.openAccNo"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="提现手续费(元)">
        <el-input
          v-model="ruleForm.feeAmt"
          disabled
          :placeholder="ruleForm.feePayWay === 1 ? '手续费费率为0.5%' : '请输入提现金额'"
        />
      </el-form-item>
      <el-form-item label="收款银行">
        <el-input v-model="ruleForm.openBankName" disabled placeholder="选择收款账户联动" />
      </el-form-item>
      <el-form-item label="收款账户名称">
        <el-input v-model="ruleForm.openAccName" disabled placeholder="选择收款账户联动" />
      </el-form-item>
      <el-form-item label="收款账户网点">
        <el-input v-model="ruleForm.openBankNet" disabled placeholder="选择收款账户联动" />
      </el-form-item>
      <el-form-item class="lastOne" label="备注">
        <el-input v-model="ruleForm.remark" type="textarea" maxlength="200" show-word-limit :rows="3" placeholder="请输入备注" />
      </el-form-item>
    </el-form>
    <div class="buttonCombination">
      <el-button plain class="widen-button" @click="resetForm('ruleForm')">
        取消
      </el-button>
      <el-button :loading="loading" type="primary" class="widen-button" @click="submitForm('ruleForm')">
        确定
      </el-button>
    </div>
  </div>
</template>

<script>
import { cashAccountDetail, inoutLogOutAdd } from '@/http/agentApi/enterpriseInformation'
import { operationMethods } from '@/http/agentApi/common'
export default {
  data() {
    return {
      ruleForm: {},
      openAccNoArr: [],
      rules: {
        settleBankId: [
          { required: true, message: '请选择结算银行', trigger: 'change' }
        ],
        amount: [
          { required: true, message: '请输入提现金额', trigger: 'blur' }
        ],
        feePayWay: [
          { required: true, message: '请选择手续费支付方', trigger: 'change' }
        ],
        operPlat: [
          { required: true, message: '请选择渠道', trigger: 'change' }
        ],
        openAccNoId: [
          { required: true, message: '请选择收款账号', trigger: 'change' }
        ]
      },
      loading: false,
      breadcrumbItem: [{ path: '/', name: '首页' }, { path: '/enterpriseInformation/accountInformation', name: '账户信息' }, { path: '', name: '新增提现' }]
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.ruleForm.cmpId = this.$route.query.cmpId
      this.ruleForm = { ...this.$route.query }
      this.ruleForm.openAccName = this.$route.query.cmpName
      // this.ruleForm.settleBankId = this.$route.query.settleBankId
      // this.ruleForm.avlbCashFund = this.$route.query.avlbCashFund
      // this.ruleForm.settleBankName = this.$route.query.settleBankName
      this.getOpenAccNo()
    }
  },
  methods: {
    // 通过账户获取其他的资料呀
    getOtherDetails() {
      this.openAccNoArr.map(item => {
        if (item.id === this.ruleForm.openAccNoId) {
          this.ruleForm.openAccNo = item.openAccNo
          this.ruleForm.openBankName = item.openBankName
          this.ruleForm.openBankNet = item.openBankNet
        }
      })
      this.$forceUpdate()
    },
    // 点击确认按钮
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 获取结算银行的名称
          const arr = this.$store.getters.getDictionaryItem('settleBankName')
          arr.forEach(item => {
            if (item.dictId === this.ruleForm.settleBankId) { this.ruleForm.settleBankName = item.dictName }
          })
          inoutLogOutAdd(this.ruleForm, () => {
            this.$message.success('新增成功！')
            setTimeout(() => {
              this.$router.go(-1)
            }, 1000)
          })
        }
      })
    },
    // 取消
    resetForm() {
      this.$router.go(-1)
    },
    // 输入提现金额计算提现手续费
    getFeeAmt() {
      // 判断不能超过可提现金额
      this.$set(this.ruleForm, 'amount', this.ruleForm.avlbCashFund < parseInt(this.ruleForm.amount) ? this.ruleForm.avlbCashFund : this.ruleForm.amount)
      this.loading = false
      // 平台支付时手续费为0
      if (this.ruleForm.feePayWay === 1) {
        this.ruleForm.feeAmt = 0
        return
      }
      operationMethods({ type: 3, param1: parseInt(this.ruleForm.amount || 0), param2: 0.005 }, res => {
        this.$set(this.ruleForm, 'feeAmt', res.data || 0)
        this.$forceUpdate()
        if (this.ruleForm.avlbCashFund < (parseInt(res.data) + parseInt(this.ruleForm.amount))) {
          this.$message.error('提现金额加手续费不能超过可提现金额！')
          this.loading = true
          return
        }
        this.loading = false
      })
    },
    getOpenAccNo() {
      if (!this.ruleForm.cmpId || !this.ruleForm.settleBankId) {
        return
      }
      cashAccountDetail({ cmpId: this.ruleForm.cmpId, settleBankId: this.ruleForm.settleBankId }, res => {
        this.openAccNoArr = [...res.data]
        if (this.openAccNoArr.length === 0) {
          this.$message('该会员在该结算银行没有提现账户，请重新选择会员名称或结算银行！')
          // 如果没有选择的  就把之前的变为空
          this.ruleForm.openAccNo = ''
          this.ruleForm.openAccNoId = ''
          this.ruleForm.openBankName = ''
          this.ruleForm.openBankNet = ''
          this.$forceUpdate()
        }
      })
    }
  }

}
</script>

<style lang="scss">
.addTopUpPageBox {
  .el-form {
    .lastOne {
      display: block;
    }
  }
  .buttonCombination {
    text-align: center;
  }
  .withdrawalAmount .el-form-item__content {
    width: calc(100% - 160px);
  }
  .el-form .lastOne {
    width: 100%;
  }
  .el-form .el-input__inner,
  .el-form .el-select,
  .el-form .el-input,
  .el-form .el-cascader {
    width: 100%;
  }
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  .table td {
    border: 1px solid #ededed;
    text-align: center;
  }
  .table td .el-input {
    width: 160px;
  }
  .table td i {
    font-size: 26px;
    cursor: pointer;
    line-height: 40px;
  }
  .table td .el-input .el-input__inner {
    border: 0;
    text-align: center;
  }
}
</style>
